











































































































import Vue from 'vue';
import { ErrorManager } from '@/models/error';
import {
  INotification,
  Notification,
  NotificationRecipients,
} from '@/models/notification';
import ErrorAlert from '../common/ErrorAlert.vue';
import { cloneDeep } from 'lodash';
import { mapActions, mapState } from 'vuex';
import { Account } from '@/models/account';

export default Vue.extend({
  components: { ErrorAlert },

  data() {
    return {
      errorMessage: '',
      message: '',
      notification: {} as INotification,
      recipientList: [] as number[] | Account[],

      dialog: false,
      disableBtn: false,
      loading: false,
      valid: false,
    };
  },
  computed: {
    ...mapState('account', ['account']),
    ...mapState('user', ['superAdminUsers']),

    users(): Account[] {
      return this.superAdminUsers.map((account: Account) => {
        return {
          full_name: account.first_name + ' ' + account.last_name,
          email: account.email,
          pk: account.pk,
        };
      });
    },
  },

  methods: {
    ...mapActions({
      fetchSuperAdminUsers: 'user/fetchSuperAdminUsers',
      createNotification: 'notification/createNotification',
    }),

    open(notification = new Notification()) {
      this.dialog = true;
      this.notification = cloneDeep(notification);
      this.clearAllErrors();
    },

    clearAllErrors() {
      this.errorMessage = '';
    },

    close() {
      this.dialog = false;
    },

    sendSANotification() {
      this.notification.title = `Package Request from ${this.account.franchise.name}`;
      this.notification.message = `Franchise request package with tracking number: ${this.message}`;

      this.notification.recipients = this.convertIntListToUserObjectList(
        this.notification.recipients as number[]
      );

      this.loading = true;

      this.clearAllErrors();

      this.createNotification(this.notification)
        .then(() => {
          this.loading = false;
          this.$emit('successfull');
          this.close();
        })
        .catch((error: any) => {
          this.errorMessage = ErrorManager.extractApiError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    convertIntListToUserObjectList(
      recipients: number[]
    ): NotificationRecipients[] {
      const recipentList = [] as any;

      for (const key in recipients) {
        const userObj = { user: recipients[key] };
        recipentList.push(userObj);
      }
      return recipentList;
    },

    /**
     * Gets called when user clicks remove icon selected emoloyee
     *
     *
     * @param assignee text string to be searched
     *
     *
     */
    removeAssignee(employee: { pk: number; full_name: string; email: string }) {
      const index = this.recipientList.indexOf(employee.pk);
      if (index >= 0) this.recipientList.splice(index, 1);
    },

    setFilterUser(
      item: { full_name: string; email: string },
      queryText: string,
      itemText: any
    ) {
      return (
        item.full_name
          .toLocaleLowerCase()
          .indexOf(queryText.toLocaleLowerCase()) > -1 ||
        item.email.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
  },

  async mounted() {
    await this.fetchSuperAdminUsers().catch((error: any) => {
      this.errorMessage = ErrorManager.extractApiError(error);
    });
  },
});
