import { Account } from './account';
import { Franchise } from './franchise';

export class Notification implements INotification {
  pk?: number;
  title?: string;
  message?: string;
  recipients?: NotificationRecipients[] | number[] | any = [];
  franchise?: Franchise;

  constructor() {
    this.franchise = new Franchise();
  }
}

export class NotificationRecipients implements INotificationRecipients {
  pk?: number;
  user?: number | Account;
  is_read?: boolean;
}

export interface INotification {
  pk?: number;
  title?: string;
  message?: string;
  recipients?: INotificationRecipients[] | number[] | undefined;
}

export interface INotificationRecipients {
  pk?: number;
  user?: number | Account;
  is_read?: boolean;
}
