var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":_vm.loading,"rounded":"lg"}},[_c('v-card-title',{staticStyle:{"justify-content":"center","margin-bottom":"24px"}},[_c('span',{staticClass:"headline"},[_vm._v(" Package Request ")])]),_c('v-card-text',[_c('ErrorAlert',{staticClass:"mb-0",attrs:{"dense":"","error-message":_vm.errorMessage},on:{"clearErrors":_vm.clearAllErrors}}),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 mb-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Tracking Number","placeholder":"Enter Tracking Number","outlined":"","hide-details":"auto"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.users,"filter":_vm.setFilterUser,"clearable":"","hide-selected":"","item-text":"full_name","item-value":"pk","placeholder":"Select for user...","label":"Recipient","multiple":"","outlined":"","hide-details":"auto"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" No User Found ")])],1)]},proxy:true},{key:"append",fn:function(){return [_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-file-document-box-search-outline ")])]},proxy:true},{key:"selection",fn:function(ref){
var attr = ref.attr;
var on = ref.on;
var item = ref.item;
var selected = ref.selected;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"white--text",attrs:{"input-value":selected,"color":"primary","close":""},on:{"click:close":function($event){return _vm.removeAssignee(item)}}},'v-chip',attr,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-account-circle-outline ")]),_c('span',{domProps:{"textContent":_vm._s(item.full_name)}})],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.full_name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.email)}})],1),_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-circle")])],1)]}}]),model:{value:(_vm.notification.recipients),callback:function ($$v) {_vm.$set(_vm.notification, "recipients", $$v)},expression:"notification.recipients"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticStyle:{"text-align":"right"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.close()}}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"disabled":!_vm.valid,"loading":_vm.loading,"color":"primary"},on:{"click":function($event){return _vm.sendSANotification()}}},[_vm._v("Send Notification ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }